import {
    Row,
    Col,
    Button,
    Card,
    Slider,
    Input,
    InputNumber,
    Modal,
    message,
    Typography,
    Pagination,
    Switch,
    Tag,
    Spin,
    Tooltip,
    Tabs,
    Checkbox,
    Space,
    notification
} from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    IssuesCloseOutlined,
    PlusCircleOutlined,
    PushpinOutlined,
    PushpinFilled,
    EditOutlined,
    EditFilled,
    CheckCircleTwoTone,
    EyeOutlined,
    RightOutlined,
    DownOutlined
} from '@ant-design/icons';

import { Table } from 'react-bootstrap';
import axios from 'axios';

import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import _, { set } from 'lodash';
import { Grid } from 'react-bootstrap';
import Chartist from 'react-chartist';
import ChartistLegend from 'chartist-plugin-legend';
import GADSBudgetAdd from './GADSBudgetAdd';
import HelperInternal from './GADSHelper';
import {
    DATE_TIME_FORMAT,
    DATE_FORMAT_SAVE,
    DATE_FORMAT_SHORT,
    DAY_FORMAT_GRAPH,
    GADS_MICRO_MULTIPLIER
} from './GADSconstants';
import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import odooImage from '../../../src/assets/img/odoo_logo.svg';
import Icon from '@ant-design/icons/lib/components/Icon';
import { UserService } from '../../services/user.service';
import copy from 'copy-to-clipboard';

const { TabPane } = Tabs;
const { Text, Title } = Typography;
const CAMPAIGN_ROW_STYLES = {
    PAUSED: { backgroundColor: '#fdd' },
    ENABLED: { backgroundColor: '#dfd' },
    REMOVED: { display: 'none' }
};
const FAKE_MESSAGE_ID = 9999999999999;

const TODAY_MOMENT = moment().format(DATE_FORMAT_SAVE);

export default props => {
    const [loadingData, setLoadingData] = useState(false);
    // how much was spent till today
    const [spendData, setSpendData] = useState(false);
    // how much planned to spend  starting form today
    const [plannedSpend, setPlannedSpend] = useState({});
    const [budgetAddMode, setBudgetAddMode] = useState(false);
    const [initialManagement, setInitialManagement] = useState({});
    const [weightsUpdateFlag, setWeightsUpdateFlag] = useState(false);
    const [allCampaigns, setAllCampaigns] = useState({});
    const [showLogModal, setShowLogModal] = useState(false);
    const [noticeEditMode, setNoticeEditMode] = useState(false);
    const [updated, setUpdated] = useState(false);

    const [notificationPage, setNotificationPage] = useState(1);
    const [notificationEdit, setNotificationEdit] = useState(false);
    const [notificationCollapse, setnotificationCollapse] = useState(true);

    const { startDate, endDate, editAccount, allAccounts, setAllAccounts, editMode, handleEditMode } = props;

    const datesArray = HelperInternal.generatesDatesArray(startDate, endDate);
    const { log, budgets, status } = initialManagement;
    const budgetsSpendPlan = [];

    useEffect(() => {
        if (
            editAccount.gads_account &&
            (initialManagement.gads_account !== editAccount.gads_account ||
                initialManagement.status !== editAccount.status) &&
            editMode
        ) {
            const initialManagement = { ...{ notifications: [] }, ..._.cloneDeep(editAccount) };
            setInitialManagement(initialManagement);
            setAllCampaigns(initialManagement.campaigns);
            if (initialManagement.planned_spend) {
                setPlannedSpend(initialManagement.planned_spend);
            }
        }
    });

    useEffect(() => {
        console.log('GADSManagementEdit useEffect initialManagement updated', editAccount.editAccount);
        if (!editMode || weightsUpdateFlag) {
            return;
        }
        getSpendData();
    }, [initialManagement]);

    // getting the current period spending plan form DB
    const getSpendData = () => {
        if (!initialManagement || !initialManagement.gads_account || loadingData) {
            return;
        }
        setLoadingData(true);
        return axios
            .get(`${API_BASE_URL}gadsmanagement/management/${initialManagement.gads_account}`, {
                headers: authHeader()
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error getting information about Management ');
            })
            .then(response => {
                if (initialManagement.gads_account in response) {
                    setSpendData(response[initialManagement.gads_account].daily_spend);
                    getSpendingPlan();
                }
            })
            .catch(error => {
                props.handleEditMode(initialManagement, false, false);
                message.error(`Error getting information about Management: ${error}`);
            })
            .finally(() => {
                setLoadingData(false);
            });
    };

    //recalculating the daily budget for each campaign based on the changes in the weights
    const getSpendingPlan = () => {
        return axios
            .post(`${API_BASE_URL}gadsmanagement/spreadbudgets`, {
                headers: authHeader(),
                account: initialManagement
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error getting information about Management ');
            })
            .then(newSpendingPlan => {
                setPlannedSpend({ ...plannedSpend, ...newSpendingPlan });
            })
            .catch(error => {
                message.error(`Error getting information about Management: ${error}`);
            });
    };

    // const weightsUpdate = (account, campaignUpdated, value, type = 'fixed') => { // campaign, value, type === 'fixed'
    //     const allCampaignsToUpdate = { ...account.campaigns };
    //     let campaignsIds = Object.keys(allCampaigns).filter(campaign => {return allCampaignsToUpdate[campaign].status === 'ENABLED'});
    //     let quantity = campaignsIds.length;
    //      if (!quantity) {
    //         return;
    //     }
    //     const processCampaigns = {}
    //     campaignsIds.forEach(campaignId => {
    //         processCampaigns[campaignId] = {...allCampaignsToUpdate[campaignId]};
    //         if (!processCampaigns[campaignId].weight) {
    //             processCampaigns[campaignId].weight = {
    //                 value: Math.floor(100 / quantity),
    //                 type: 'percentage',
    //             };
    //         }
    //     });

    //     let rest = 100 - value;
    //     const ignoredCampaigns = [...initialManagement.connectors.map(item=>item.campaign_id), ...initialManagement.budgets.map(item=>item.campaign_id),campaignUpdated].filter(item=>!!item);
    //     let sumOfRest = campaignsIds
    //         .filter(campaign => {
    //             return (ignoredCampaigns.indexOf(campaign)===-1);
    //         })
    //         .reduce((sum, campaign) => {
    //             return (
    //                 sum +
    //                 (processCampaigns[campaign].weight.type!=='percentage'
    //                     ? 0
    //                     : Number(processCampaigns[campaign].weight.value))
    //             );
    //         }, 0);
    //     if (rest < sumOfRest) {
    //         let canBeReduced = campaignsIds.filter(campaign => {
    //             return (ignoredCampaigns.indexOf(campaign)===-1) && processCampaigns[campaign].weight.value>=1;
    //         });
    //         for (let i = 0; i < (sumOfRest - rest); i+=0.1) {
    //             const index = Math.round(i*10) % canBeReduced.length
    //             if (processCampaigns[canBeReduced[index]].weight.value >= 0.1)
    //                 {processCampaigns[canBeReduced[index]].weight.value = Math.round(100 * processCampaigns[canBeReduced[index]].weight.value-10)/100;}

    //         }
    //     }
    //     processCampaigns[campaignUpdated].weight.value = value;
    //     return account

    // }

    const weightsSaveToDb = () => {
        const newMessage = {
            gadsAccount: initialManagement.gads_account,
            user: UserService.getInfoFromStorage().user.id,
            message: `<b>Saving weights to the database: <br></b>${Object.values(initialManagement.campaigns)
                .map(
                    campaign =>
                        campaign.name +
                        ': ' +
                        (!campaign.weight
                            ? '-'
                            : campaign.weight.type === 'percentage'
                            ? campaign.weight.value + '%'
                            : `$${campaign.weight.value}`)
                )
                .join('<br>')}`,
            pinned: false,
            editable: false,
            type: 'user_info',
            id: FAKE_MESSAGE_ID
        };
        let additionalData = {};
        return updatingAdsManagementAccount(initialManagement.gads_account, { campaigns: initialManagement.campaigns })
            .then(response => {
                return updatingAdsManagement(initialManagement.gads_account, { planned_spend: plannedSpend });
            })
            .then(response => {
                additionalData = {
                    ...response,
                    ...{ notification: [...initialManagement.notification, ...[newMessage]] }
                };
                return saveMessage(newMessage);
            })
            .then(response => {
                return refreshManagementData(initialManagement.gads_account, { ...additionalData });
            });
    };

    const addLoggingRecord = text => {
        const { initialManagement } = this.state;

        if (!initialManagement.log) {
            initialManagement.log = [];
        }
        initialManagement.log.push({ date: moment().format(), text });
    };

    const updatingAdsManagement = (gadsAccountId, data) => {
        if (!('service_period' in data)) {
            data.service_period = moment().startOf('month').format(DATE_FORMAT_SAVE);
        }
        if ('budgets' in data) {
            data.bar_data = initialManagement.bar_data;
        }
        return axios
            .put(`${API_BASE_URL}gadsmanagement/management`, {
                headers: authHeader(),
                data,
                gadsAccount: gadsAccountId
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error saving Management data to DB.');
            })
            .then(response => {
                message.success(`Management data updated`);
            })
            .catch(error => {
                message.error(`Management Data wasn't updated, Sorry: ${error}`);
            });
    };

    const updatingAdsManagementAccount = (gadsAccountId, data) => {
        return axios
            .put(`${API_BASE_URL}gadsmanagement/managementaccount`, {
                headers: authHeader(),
                data,
                gadsAccount: gadsAccountId
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error saving Account Settings data to DB.');
            })
            .then(response => {
                const initialManagementEdit = { ...initialManagementEdit, ...{ ...{ campaigns: data } } };
                setWeightsUpdateFlag(false);
                message.success(`Account Management updated`);
            })
            .catch(error => {
                message.error(`Account Management wasn't updated, Sorry: ${error}`);
            });
    };

    const refreshManagementData = (gadsAccountId, data) => {
        allAccounts.forEach((item, index) => {
            if (item.gads_account === gadsAccountId) {
                allAccounts[index] = { ...item, ...data };
            }
        });
        const initialManagementEdit = { ...initialManagement, ...data };
        setInitialManagement(initialManagementEdit);
        setAllAccounts(allAccounts);
        setWeightsUpdateFlag(false);
        props.getAllAccounts();
    };

    const pauseCampaign = (campaignId, operation) => {
        const newInitialManagement = { ...initialManagement };
        newInitialManagement.campaigns[campaignId].status = 'PAUSED';
        setInitialManagement(newInitialManagement);
        updatingAdsManagementAccount(initialManagement.gads_account, { campaigns: newInitialManagement.campaigns });
        setWeightsUpdateFlag(true);
        return axios
            .put(`${API_BASE_URL}gadsmanagement/campaign/pause`, {
                headers: authHeader(),
                campaign: campaignId,
                operation,
                gadsAccount: initialManagement.gads_account
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error pausing the campaign.');
            })
            .then(response => {
                return handleRefreshCampaignsFromGADS();
            })
            .then(response => {
                message.success(`GADs Campaign was paused.`);
            })
            .catch(error => {
                message.error(`GADs Campaign Budgets wasn't updated, Sorry: ${error}`);
            })
            .finally(response => {
                setWeightsUpdateFlag(false);
            });
    };

    const updateBudgetsGADS = () => {
        const plannedSpendToday = plannedSpend[TODAY_MOMENT] || false;
        const newMessage = {
            gadsAccount: initialManagement.gads_account,
            user: UserService.getInfoFromStorage().user.id,
            message: `<b>Google ADs updated with budgets: </b><br>${Object.values(initialManagement.campaigns)
                .map(campaign => campaign.name + ': ' + plannedSpendToday[campaign.id].toFixed(2))
                .join('<br>')}`,
            pinned: false,
            editable: false,
            type: 'user_info',
            id: FAKE_MESSAGE_ID
        };
        let additionalData = {};
        if (!plannedSpendToday) {
            return;
        }
        return axios
            .put(`${API_BASE_URL}gadsmanagement/budgets`, {
                headers: authHeader(),
                campaigns: initialManagement.campaigns,
                planned_spend: plannedSpend,
                gadsAccount: initialManagement.gads_account
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error saving Account Settings data to DB.');
            })
            .then(response => {
                additionalData = { notification: [...initialManagement.notification, ...[newMessage]] };
                return handleRefreshCampaignsFromGADS();
            })
            .then(response => {
                message.success(`GADs Campaign Budgets was updated.`);
                return saveMessage(newMessage);
            })
            .then(response => {
                refreshManagementData(initialManagement.gads_account, additionalData);
                setWeightsUpdateFlag(false);
            })
            .catch(error => {
                message.error(`GADs Campaign Budgets wasn't updated, Sorry: ${error}`);
            });
    };

    const updateBudgetsOdoo = () => {
        const newMessage = {
            gadsAccount: initialManagement.gads_account,
            user: UserService.getInfoFromStorage().user.id,
            message: `<b>Budgets updated from Odoo: <br></b>`,
            pinned: false,
            editable: false,
            type: 'user_info',
            id: FAKE_MESSAGE_ID
        };
        let additionalData = {};
        return axios
            .get(`${API_BASE_URL}gadsmanagement/updateodoobudgets/${initialManagement.gads_account}/googleads`, {
                headers: authHeader()
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error saving Account Settings data to DB.');
            })
            .then(response => {
                if (!response.accountGADSdata) {
                    throw new Error('No data received');
                }
                additionalData = {
                    ...response.updateData,
                    ...{ notification: [...[newMessage]] }
                };
                if (initialManagement.notification) {
                    additionalData.notification = [...initialManagement.notification, ...[newMessage]];
                }
                if (response.updateSettings) {
                    additionalData = { ...additionalData, ...response.updateSettings };
                }
                newMessage.message += response.updateData.budgets
                    .map(item => item.subscription_name + ': $' + item.wholesale_price.toFixed(2))
                    .join('<br>');
                message.success(`Account Management updated`);
                return saveMessage(newMessage);
            })
            .then(response => {
                refreshManagementData(initialManagement.gads_account, { ...additionalData });
            })
            .catch(error => {
                message.error(`Account Management wasn't updated with Odoo budgets, Sorry: ${error}`);
            });
    };

    const handleRefreshCampaignsFromGADS = () => {
        return axios
            .get(`${API_BASE_URL}gadsmanagement/updateallcampaignsfromgad/${initialManagement.gads_account}`, {
                headers: authHeader()
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Refreshing campaigns error.');
            })
            .then(response => {
                if (!response) {
                    throw new Error('No data received');
                }
                const campaigns = { ...response };
                Object.keys(campaigns).forEach(campaign => {
                    if (initialManagement.campaigns[campaign] && 'weight' in initialManagement.campaigns[campaign]) {
                        campaigns[campaign].weight = initialManagement.campaigns[campaign].weight;
                    }
                });
                const initialManagementEdit = { ...initialManagement, ...{ campaigns } };
                setInitialManagement(initialManagementEdit);
                updatingAdsManagementAccount(initialManagement.gads_account, { campaigns });
                message.success(`Campaigns List updated`);
            })
            .catch(error => {
                message.error(`Campaigns List wasn't updated, Sorry: ${error}`);
            });
    };

    const handleWeightChange = (campaign, value) => {
        const newInitialManagement = { ...initialManagement };
        if (!newInitialManagement.campaigns[campaign].weight) {
            newInitialManagement.campaigns[campaign].weight = { type: 'percentage', value: value };
        } else {
            newInitialManagement.campaigns[campaign].weight.value = value;
        }
        setInitialManagement(newInitialManagement);
        _.debounce(() => {
            // weightsUpdate(newInitialManagement, campaign, value)
            setWeightsUpdateFlag(true);
            getSpendingPlan();
        }, 1000)();
    };

    const handleWeightTypeChange = (campaign, flagName) => {
        const newInitialManagement = { ...initialManagement };
        newInitialManagement.campaigns[campaign].weight = { type: flagName, value: 0 };
        // weightsUpdate(newInitialManagement,campaign, 0,flagName)
        setInitialManagement(newInitialManagement);
        setWeightsUpdateFlag(true);
        getSpendingPlan();
    };

    const handleBudgetsChange = budgets => {
        initialManagement.budgets = budgets || [];
        updatingAdsManagement(initialManagement.gads_account, { budgets: initialManagement.budgets });
        getSpendingPlan();
        // const initialManagement = HelperInternal.getBarData(initialManagement.budgets, initialManagement.campaigns);
        allAccounts.filter(item => item.gads_account === initialManagement.gads_account)[0].budgets =
            initialManagement.budgets;
        setAllAccounts(allAccounts);
        // need loggging to be added
    };

    const handleBudgetsDelete = budgetIndex => {
        let logginText = `Budget ${initialManagement.budgets[budgetIndex].type}:$${initialManagement.budgets[budgetIndex].budget} deleted.`;
        if (initialManagement.budgets[budgetIndex]) {
            initialManagement.budgets.splice(budgetIndex, 1);
        }
        updatingAdsManagement(initialManagement.gads_account, { budgets: initialManagement.budgets });
        getSpendingPlan();
        setInitialManagement(initialManagement);

        // TODO: add logging
    };

    const handleNewBudgetMode = () => {
        setBudgetAddMode(!budgetAddMode);
    };

    const handleCancel = () => {
        props.handleEditMode(false, props.editAccount, false, '');
    };

    const handleCopyToClipboard = (text, mes = 'Copied to clipboard') => {
        copy(text);
        message.info(mes);
    };

    const handleLogButtons = () => {
        if (!initialManagement.notification || !initialManagement.notification.length) {
            message.error(`No any logs.`);
            return;
        }
        setShowLogModal(!showLogModal);
    };

    const handleNewMessage = () => {
        const newInitialManagement = { ...initialManagement };
        if (!!newInitialManagement.notification.filter(item => item.id === FAKE_MESSAGE_ID).length) {
            message.info(`Please save the message before adding a new one.`);
        }
        newInitialManagement.notification.push({
            message: '',
            date: moment().format(DATE_TIME_FORMAT),
            pinned: true,
            editable: true,
            id: FAKE_MESSAGE_ID,
            type: 'user_info'
        });
        setInitialManagement(newInitialManagement);
        setNotificationEdit(FAKE_MESSAGE_ID);
    };

    const pinMessage = (accountID, id, pinned) => {
        return axios
            .put(
                `${API_BASE_URL}gadsmanagement/pinmessage`,
                {
                    id,
                    pinned,
                    accountID
                },
                {
                    headers: authHeader()
                }
            )
            .then(response => {
                const newInitialManagement = { ...initialManagement };
                newInitialManagement.notification = newInitialManagement.notification.map(notification => {
                    return id === notification.id ? { ...notification, ...{ pinned } } : notification;
                });
                setInitialManagement(newInitialManagement);
                setNotificationEdit(false);
            });
    };

    const saveMessage = messageParams => {
        const newInitialManagement = { ...initialManagement };
        const messageID = messageParams.id;

        const messageSave = {
            gadsAccount: initialManagement.gads_account,
            user: UserService.getInfoFromStorage().user.id,
            message: messageParams.message,
            pinned: messageParams.pinned,
            editable: messageParams.editable,
            type: messageParams.type
        };

        if (messageParams.id !== FAKE_MESSAGE_ID) {
            messageSave.message_id = messageParams.id;
        }

        return axios
            .put(`${API_BASE_URL}gadsmanagement/message`, messageSave, {
                headers: authHeader()
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error saving management data.');
            })
            .then(newMessage => {
                if (newMessage) {
                    message.success(`Message saved`);
                    if (newMessage.insertId && messageParams.id === FAKE_MESSAGE_ID) {
                        const newMessageId = newMessage.insertId;
                        newInitialManagement.notification = newInitialManagement.notification.map(item =>
                            item.id === messageID ? { ...item, ...{ id: newMessageId } } : item
                        );
                    }
                    setInitialManagement(newInitialManagement);
                    setNotificationEdit(false);
                } else {
                    message.error(`Updating message error - no message ID received.`);
                }
            })
            .catch(error => {
                message.error(`Updating message error: ${error}`);
            });
    };

    const renderLoggingModule = (log = []) => {
        const iconStyles = {
            height: '0.5em',
            fontSize: 'medium'
        };
        const notificationLine = logLine => {
            return (
                <tr
                    key={'log-line-' + logLine.id}
                    justify="start"
                    style={{ borderBottom: 'solid 1px #eeeeee', position: 'relative' }}
                >
                    <td>
                        <Row style={{ fontSize: 10 }}>{moment(logLine.date).format('MM/DD/YY HH:mm')}</Row>
                        <Row>
                            <span>
                                {logLine.pinned ? (
                                    <PushpinFilled
                                        style={iconStyles}
                                        onClick={() =>
                                            pinMessage(initialManagement.gads_account, logLine.id, !logLine.pinned)
                                        }
                                    />
                                ) : (
                                    <PushpinOutlined
                                        style={iconStyles}
                                        onClick={() =>
                                            pinMessage(initialManagement.gads_account, logLine.id, !logLine.pinned)
                                        }
                                    />
                                )}
                            </span>
                            <span>
                                {logLine.editable ? (
                                    <EditFilled
                                        style={iconStyles}
                                        onClick={() =>
                                            pinMessage(initialManagement.gads_account, logLine.id, !logLine.pinned)
                                        }
                                    />
                                ) : (
                                    <EditOutlined
                                        style={iconStyles}
                                        onClick={() =>
                                            pinMessage(initialManagement.gads_account, logLine.id, !logLine.pinned)
                                        }
                                    />
                                )}
                            </span>
                            <span>
                                {!logLine.pinned ? (
                                    <EyeOutlined
                                        style={iconStyles}
                                        onClick={() => {
                                            props
                                                .markMessageReaded([logLine.id], initialManagement.gads_account)
                                                .then(() => setInitialManagement(_.cloneDeep(editAccount)));
                                        }}
                                    />
                                ) : null}
                            </span>
                        </Row>
                    </td>
                    <td>
                        {logLine.id === notificationEdit ? (
                            <Input.TextArea
                                autoSize={{ minRows: 1, maxRows: 3 }}
                                className="log-line"
                                defaultValue={logLine.message}
                                onBlur={item => {
                                    logLine.message = item.target.value;
                                }}
                            />
                        ) : (
                            <div
                                dangerouslySetInnerHTML={{ __html: logLine.message }}
                                onClick={notificationEdit => {
                                    if (logLine.editable) {
                                        setNotificationEdit(logLine.id);
                                    }
                                }}
                                className="log-line"
                            />
                        )}
                    </td>
                    <td>
                        {logLine.id === notificationEdit ? (
                            <Tooltip
                                placement="top"
                                title="Save Changes"
                                className="add-budget-button"
                                onClick={() => saveMessage({ ...logLine })}
                            >
                                <CheckCircleTwoTone twoToneColor="green" />
                            </Tooltip>
                        ) : null}
                    </td>
                    <td>
                        <Text style={{ position: 'absolute', bottom: 0, right: 0, textAlign: 'right', fontSize: 10 }}>
                            {!logLine.user || logLine.user === '0'
                                ? 'system'
                                : props.allUsers[logLine.user]
                                ? props.allUsers[logLine.user].last_name + ' ' + props.allUsers[logLine.user].first_name
                                : '-'}
                        </Text>
                    </td>
                </tr>
            );
        };

        let pinned = [];
        let unpinned = [];
        log.forEach(item => {
            if (item.pinned) {
                pinned.push(item);
            } else {
                unpinned.push(item);
            }
        });
        return (
            <Card
                title={
                    <React.Fragment>
                        <Tooltip placement="top" title="Show messages" className="add-budget-button">
                            <Typography className="" onClick={() => setnotificationCollapse(!notificationCollapse)}>
                                {notificationCollapse ? <RightOutlined /> : <DownOutlined />}Messages
                            </Typography>
                        </Tooltip>
                    </React.Fragment>
                }
                extra={
                    <React.Fragment>
                        <Space wrap>
                            <Tooltip placement="top" title="Add message" className="add-budget-button">
                                <Button type="primary" className="" onClick={handleNewMessage}>
                                    <PlusCircleOutlined type="primary" />
                                </Button>
                            </Tooltip>
                        </Space>
                    </React.Fragment>
                }
                className="budgets-card"
            >
                {!notificationCollapse ? (
                    <React.Fragment>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                {pinned.length || unpinned.length
                                    ? [pinned, unpinned].map(messagesList => {
                                          return messagesList
                                              .sort((a, b) => {
                                                  return b.date > a.date ? 1 : -1;
                                              })
                                              .slice((notificationPage - 1) * 5, notificationPage * 5)
                                              .map((logLine, index) => {
                                                  return notificationLine(logLine);
                                              });
                                      })
                                    : 'No messages'}
                            </tbody>
                        </table>
                        <Pagination
                            total={log.length}
                            showSizeChanger={false}
                            showQuickJumper
                            pageSize={5}
                            current={notificationPage}
                            // pageSizeOptions={['25', '50', '100']}
                            // showTotal={total => `Total ${total} items`}
                            onChange={page => setNotificationPage(page)}
                            // onShowSizeChange={handlePageSize}
                        />
                    </React.Fragment>
                ) : null}
            </Card>
        );
    };

    // campaign - array of
    const renderCampaignTableRow = campaignId => {
        if (!(campaignId in allCampaigns)) {
            return (
                <tr>
                    <td> -- REMOVED -- </td>
                </tr>
            );
        }
        const campaign = allCampaigns[campaignId];
        return (
            <tr key={'campaign-row-' + campaignId}>
                <td>
                    <Text strong>{campaign.name}</Text>
                </td>
                {datesArray.map((dateD, index) => {
                    return (
                        <td>
                            <Text>
                                {!spendData[dateD] || !spendData[dateD][campaignId]
                                    ? '0'
                                    : (spendData[dateD][campaignId] / GADS_MICRO_MULTIPLIER).toFixed(2)}
                            </Text>
                            <br />
                            <Text className="planned-spend">
                                {!plannedSpend || !plannedSpend[dateD] || !plannedSpend[dateD][campaignId]
                                    ? '0'
                                    : plannedSpend[dateD][campaignId].toFixed(2)}
                            </Text>
                        </td>
                    );
                })}
            </tr>
        );
    };

    const renderTableFormat = () => {
        let totalForToday = 0;
        if (!_.isEmpty(plannedSpend)) {
            totalForToday = Object.keys(plannedSpend).reduce((sum, account) => {
                const accountToday = account[TODAY_MOMENT];
                if (!accountToday) {
                    return sum;
                }
                return sum + (accountToday[0] === '$' ? accountToday.slice(1) : accountToday);
            }, 0);
        }
        return spendData && plannedSpend ? (
            <Table className="budget-spend">
                <thead>
                    <tr>
                        <th>Name</th>
                        {datesArray.map(dateD => {
                            return <th>{moment(dateD).format(DATE_FORMAT_SHORT)}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(allCampaigns).map(campaign => {
                        return renderCampaignTableRow(campaign);
                    })}
                    <tr>
                        <td>
                            <Text strong>Summary</Text>
                        </td>
                        {datesArray.map(dateD => {
                            let printSum = !!spendData[dateD]
                                ? (
                                      Object.values(spendData[dateD]).reduce((acc, val) => parseInt(val) + acc, 0) /
                                      GADS_MICRO_MULTIPLIER
                                  ).toFixed(2)
                                : '-';
                            let style = 'summary-line strong';
                            if (dateD >= TODAY_MOMENT && plannedSpend[dateD]) {
                                printSum = !!plannedSpend[dateD]
                                    ? Object.values(plannedSpend[dateD])
                                          .reduce((acc, val) => val + acc, 0)
                                          .toFixed(2)
                                    : '-';
                                style += ' mark';
                            }
                            if (dateD === TODAY_MOMENT && totalForToday) {
                                printSum = totalForToday.toFixed(2);
                            }
                            return (
                                <td>
                                    <Text className={style}>{printSum}</Text>
                                </td>
                            );
                        })}
                    </tr>
                </tbody>
            </Table>
        ) : null;
    };

    const renderGraphFormat = () => {
        let passedSeries = [];
        let futureSeries = [];
        let desiredSeries = [];
        let dailySumDesired = {};
        const summaryByDates = HelperInternal.summaryByDates(startDate, endDate, spendData);
        if (!summaryByDates) {
            return;
        }
        if (!_.isEmpty(plannedSpend)) {
            Object.keys(plannedSpend).forEach(day => {
                Object.keys(plannedSpend[day]).forEach(account => {
                    if (!(day in dailySumDesired)) {
                        dailySumDesired[day] = 0;
                    }
                    dailySumDesired[day] += plannedSpend[day][account];
                });
            });
        }

        const dayArrayFormatted = datesArray.map(day => {
            const dayFormatted = moment(day).format(DAY_FORMAT_GRAPH);
            if (day <= TODAY_MOMENT) {
                if (day !== TODAY_MOMENT) {
                    passedSeries.push({ x: dayFormatted, y: summaryByDates.summaryByDates[day] });
                }
                futureSeries.push({ x: dayFormatted, y: null });
            } else {
                passedSeries.push({ x: dayFormatted, y: null });
                futureSeries.push({ x: dayFormatted, y: 0 });
            }
            desiredSeries.push(day in dailySumDesired ? dailySumDesired[day] : null);
            return dayFormatted;
        });

        let data = {
            labels: dayArrayFormatted,
            series: [passedSeries, desiredSeries, futureSeries]
        };
        const high = Math.max(data.series);
        let options = {
            high: high,
            low: 0,
            axisX: {
                labelInterpolationFnc: function (value, index) {
                    return index % 2 === 0 ? value : null;
                }
            },
            plugins: [
                ChartistLegend({
                    legendNames: ['Actual Spending', 'Future Plan Spending', 'Past Planned Spending']
                })
            ]
        };

        let type = 'Line';

        return <Chartist data={data} options={options} type={type} />;
    };

    const renderGraphSumFormat = () => {
        let plannedSeries = [];
        let realSpendSeries = [];
        let summary = 0;
        if (!spendData) {
            return;
        }
        const summaryByDates = HelperInternal.summaryByDates(startDate, endDate, spendData);

        const dayArrayFormatted = datesArray.map(day => {
            const dayFormatted = moment(day).format(DAY_FORMAT_GRAPH);
            summary += summaryByDates.summaryByDates[day];
            realSpendSeries.push({ x: dayFormatted, y: day < TODAY_MOMENT ? summary : null });
            plannedSeries.push({
                x: dayFormatted,
                y: budgetsSpendPlan && day in budgetsSpendPlan ? budgetsSpendPlan[day] : 0
            });
            return dayFormatted;
        });

        let data = {
            labels: dayArrayFormatted,
            series: [[], [], [], plannedSeries, realSpendSeries]
        };
        const high = Math.max(data.series);
        let options = {
            high: high,
            low: 0,
            axisX: {
                labelInterpolationFnc: function (value, index) {
                    return index % 2 === 0 ? value : null;
                }
            },
            plugins: [
                ChartistLegend({
                    legendNames: ['', '', '', 'Prognosis Spending', 'Commulative spend']
                })
            ]
        };

        let type = 'Line';

        return <Chartist className="cumulative" data={data} options={options} type={type} />;
    };

    const renderCampaingsWeight = campaigns => {
        const rowsStyles = CAMPAIGN_ROW_STYLES;
        let ignoredCampaigns = [];
        // if (initialManagement.connectors && Object.values(initialManagement.connectors).length) {
        //     ignoredCampaigns = [
        //         ...ignoredCampaigns,
        //         ...Object.values(initialManagement.connectors).map(item => item.campaign_id)
        //     ];
        // }
        if (initialManagement.budgets) {
            ignoredCampaigns = [
                ...ignoredCampaigns,
                ...initialManagement.budgets.map(item => item.type == 'odoo' && item.campaign_id)
            ];
        }
        ignoredCampaigns = ignoredCampaigns.filter(item => !!item);
        const editableCampaign = campaign => {
            return ignoredCampaigns.indexOf(campaign.id) > -1 || campaign.status === 'PAUSED';
        };
        if (!campaigns) {
            return;
        }
        return (
            <Card
                title={
                    'Campaigns (' +
                    Object.values(campaigns)
                        .reduce((sum, campaign) => {
                            if (campaign.status !== 'ENABLED') {
                                return sum;
                            }
                            return (
                                sum +
                                (ignoredCampaigns.indexOf(campaign.id) === -1 &&
                                campaign.weight &&
                                campaign.weight.value &&
                                campaign.weight.type &&
                                campaign.weight.type === 'percentage'
                                    ? campaign.weight.value
                                    : 0)
                            );
                        }, 0)
                        .toFixed(2) +
                    '%)'
                }
                extra={
                    <Space wrap>
                        <Tooltip title="Refresh campaigns list from GADs" color={'cyan'}>
                            <Button type="primary" onClick={handleRefreshCampaignsFromGADS}>
                                REFRESH
                            </Button>
                        </Tooltip>
                        <Button type="primary" onClick={weightsSaveToDb} disabled={!weightsUpdateFlag}>
                            SAVE WEIGHTS
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                const initialManagement = _.cloneDeep(editAccount);
                                setWeightsUpdateFlag(false);
                                setInitialManagement(initialManagement);
                                setAllCampaigns(initialManagement.campaigns);
                            }}
                            disabled={!weightsUpdateFlag}
                        >
                            RESET WEIGHTS
                        </Button>
                        <Button type="primary" onClick={updateBudgetsGADS} disabled={false}>
                            Update GADS
                        </Button>
                    </Space>
                }
                className="campaigns-card"
            >
                <table style={{ width: '100%' }}>
                    <tbody>
                        {!campaigns ? (
                            <div>No Campaigns</div>
                        ) : Object.keys(campaigns).length ? (
                            Object.keys(campaigns).map(campaign => {
                                const campaignObj = campaigns[campaign];
                                const { name = '-', weight = { type: 'percentage', value: 0 } } = campaignObj;
                                const { type = 'percentage', value = 0 } = weight;
                                const camaignStatus = campaignObj.status || 'ENABLED';
                                const spend = campaignObj.metricSpend / GADS_MICRO_MULTIPLIER;
                                const todaySpend = (plannedSpend && plannedSpend[TODAY_MOMENT]) || {};
                                return (
                                    <tr key={'campaign-' + campaign} style={rowsStyles[camaignStatus]}>
                                        <td>
                                            <Tooltip title={campaignObj.id} color={'cyan'}>
                                                <Text
                                                    style={{ color: '#0000C1', cursor: 'pointer', margin: '2px 10px' }}
                                                    onClick={() =>
                                                        handleCopyToClipboard(
                                                            campaignObj.id,
                                                            'Campaign ID copied to clipboard'
                                                        )
                                                    }
                                                >
                                                    {name}
                                                </Text>
                                            </Tooltip>
                                            <Tooltip title="Google Ads Spending" color={'cyan'}>
                                                <Tag color="orange" className="current-gads-budget">
                                                    {'$ ' + (!spend ? '0' : spend.toFixed(2))}
                                                </Tag>
                                            </Tooltip>
                                            {campaign in todaySpend ? (
                                                <Tooltip title="Planned Spending" color={'cyan'}>
                                                    <Tag color="#87d068" className="corner-budget">
                                                        {'$ ' +
                                                            (!todaySpend || !todaySpend[campaign]
                                                                ? '0'
                                                                : todaySpend[campaign].toFixed(2))}
                                                    </Tag>
                                                </Tooltip>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            <Tooltip title="Search impr. share" color={'cyan'}>
                                                <Tag
                                                    color={
                                                        campaignObj.searchImpressionShare &&
                                                        campaignObj.searchImpressionShare > 0.9
                                                            ? '#ff000c'
                                                            : '#cdb197'
                                                    }
                                                    className="corner-budget"
                                                    style={{ margin: '0px' }}
                                                >
                                                    {campaignObj.searchImpressionShare
                                                        ? (100 * campaignObj.searchImpressionShare).toFixed(2)
                                                        : '-'}
                                                </Tag>
                                            </Tooltip>
                                            /
                                            <Tooltip title="Search lost IS (budget)" color={'cyan'}>
                                                <Tag color="#cdb197" className="corner-budget">
                                                    {campaignObj.searchBudgetLostImpressionShare
                                                        ? (100 * campaignObj.searchBudgetLostImpressionShare).toFixed(2)
                                                        : '-'}
                                                </Tag>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title="Pause/Unpause Campaign">
                                                <Button
                                                    type="default"
                                                    size="small"
                                                    onClick={() =>
                                                        pauseCampaign(
                                                            campaignObj.id,
                                                            camaignStatus === 'ENABLED' ? 'PAUSED' : 'ENABLED'
                                                        )
                                                    }
                                                    disabled={false}
                                                    icon={
                                                        camaignStatus === 'ENABLED' ? (
                                                            <CloseOutlined />
                                                        ) : (
                                                            <CheckOutlined />
                                                        )
                                                    }
                                                    style={{ background: '#d5e39c' }}
                                                ></Button>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title="Fixed value">
                                                <Checkbox
                                                    checked={type === 'fixed'}
                                                    onChange={evt => {
                                                        if (!evt.target.checked) {
                                                            handleWeightTypeChange(campaign, 'percentage');
                                                        } else {
                                                            handleWeightTypeChange(campaign, 'fixed');
                                                        }
                                                    }}
                                                    disabled={editableCampaign(campaignObj)}
                                                    style={{ padding: '3px' }}
                                                />
                                            </Tooltip>
                                        </td>
                                        <td style={{ width: '50%', minWidth: '300px' }}>
                                            {type === 'fixed' ? (
                                                <Input
                                                    defaultValue={value}
                                                    addonBefore="$"
                                                    placeholder="Value"
                                                    onChange={evt => {
                                                        handleWeightChange(campaign, evt.target.value);
                                                    }}
                                                    disabled={editableCampaign(campaignObj)}
                                                />
                                            ) : (
                                                <Slider
                                                    min={0.0}
                                                    max={100.0}
                                                    step={0.1}
                                                    className="weight-slider"
                                                    value={value}
                                                    disabled={editableCampaign(campaignObj)}
                                                    onChange={value => {
                                                        handleWeightChange(campaign, value);
                                                    }}
                                                    railStyle={{ color: 'black' }}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {type === 'fixed' ? null : (
                                                <InputNumber
                                                    className="notice-edit-field-textarea"
                                                    step="0.1"
                                                    value={value}
                                                    max="100"
                                                    min="0"
                                                    onChange={value => {
                                                        handleWeightChange(campaign, value);
                                                    }}
                                                    disabled={editableCampaign(campaignObj)}
                                                ></InputNumber>
                                            )}
                                        </td>
                                    </tr>
                                    // <Row key={'campaign-'+campaign} style={rowsStyles[camaignStatus]}>
                                    //     <Col span={9}>
                                    //         <Text style={{color:"#0000C1",cursor:"pointer"}} onClick={()=>handleCopyToClipboard(campaignObj.id,'Campaign ID copied to clipboard')} >{name}</Text>
                                    //             <Tooltip title="Google Ads Spending" color={'cyan'} >
                                    //                 <Tag color="orange" className="current-gads-budget">
                                    //                 {'$ ' +
                                    //                     (!spend
                                    //                         ? '0'
                                    //                         : spend.toFixed(2))}
                                    //             </Tag></Tooltip>
                                    //         {campaign in todaySpend ? (<Tooltip title="Planned Spending" color={'cyan'} >
                                    //             <Tag color="#87d068" className="corner-budget" >
                                    //                 {'$ ' +
                                    //                     (!todaySpend || !todaySpend[campaign]
                                    //                         ? '0'
                                    //                         : todaySpend[campaign].toFixed(2))}
                                    //             </Tag>
                                    //             </Tooltip>
                                    //         ) : (
                                    //             ''
                                    //         )}
                                    //     </Col>
                                    //     <Col span={1}>
                                    //         <Tooltip title="Fixed value">
                                    //             <Checkbox
                                    //                 checked={type === 'fixed'}
                                    //                 onChange={evt => {
                                    //                     if (!evt.target.checked) {
                                    //                         handleWeightTypeChange(campaign, 'percentage');
                                    //                     } else {
                                    //                         handleWeightTypeChange(campaign, 'fixed');
                                    //                     }
                                    //                 }}
                                    //                 disabled={editableCampaign(campaignObj)}
                                    //             />
                                    //         </Tooltip>
                                    //     </Col>
                                    //     <Col span={13}>
                                    //         {type === 'fixed' ? (
                                    //             <Row>
                                    //                 {/* placeholder for future fixed amount option*/
                                    //                 /*<Col span={2}>
                                    //                     <Tooltip title="Exclude of total">
                                    //                         <Checkbox
                                    //                             checked={partOfTotal}
                                    //                             onChange={value => {
                                    //                                 handleWeightTypeChange(campaign, 'partOfTotal');
                                    //                             }}
                                    //                         />
                                    //                     </Tooltip>
                                    //                         </Col> */}
                                    //                 <Col span={16}>
                                    //                     <Input
                                    //                         defaultValue={value}
                                    //                         addonBefore="$"
                                    //                         placeholder="Value"
                                    //                         onChange={evt => {
                                    //                             handleWeightChange(campaign, evt.target.value);
                                    //                         }}
                                    //                         disabled={editableCampaign(campaignObj)}
                                    //                     />
                                    //                 </Col>
                                    //             </Row>
                                    //         ) : (
                                    //             <Row>
                                    //                 <Col span={22}>
                                    //                     <Slider
                                    //                         min={0.0}
                                    //                         max={100.0}
                                    //                         step={0.1}
                                    //                         className="weight-slider"
                                    //                         value={value}
                                    //                         disabled = {editableCampaign(campaignObj)}
                                    //                         onChange={value => {
                                    //                             handleWeightChange(campaign, value);
                                    //                         }}
                                    //                     />
                                    //                 </Col>
                                    //                 <Col span={2}>
                                    //                 <InputNumber
                                    //                     className="notice-edit-field-textarea"
                                    //                     step="0.1"
                                    //                     value={value}
                                    //                     max="100"
                                    //                     min="0"
                                    //                     onChange={value => {
                                    //                         handleWeightChange(campaign, value);
                                    //                     }}
                                    //                     disabled = {editableCampaign(campaignObj)}
                                    //                 ></InputNumber>
                                    //                 </Col>
                                    //             </Row>
                                    //         )}
                                    //     </Col>
                                    // </Row>
                                );
                            })
                        ) : (
                            <Text>No data</Text>
                        )}
                    </tbody>
                </table>
            </Card>
        );
    };

    const renderBudgets = budgets => {
        const budgetsRender = budgets || [];

        return (
            <Card
                title="Budgets"
                extra={
                    <React.Fragment>
                        <Space wrap>
                            <Button type="primary" className="" onClick={updateBudgetsOdoo}>
                                ODOO Budgets Update
                            </Button>
                            <Tooltip placement="top" title="Add budget" className="add-budget-button">
                                <Button type="primary" className="" onClick={handleNewBudgetMode}>
                                    <PlusCircleOutlined type="primary" />
                                </Button>
                            </Tooltip>
                            <Button type="primary" onClick={handleLogButtons}>
                                Log
                            </Button>
                        </Space>
                    </React.Fragment>
                }
                className="budgets-card"
            >
                <Row
                    type="flex"
                    justify="space-around"
                    align="middle"
                    className="budget-row"
                    style={{ fontSize: '10px', textAlign: 'center' }}
                >
                    <Col span={9}>Subscription</Col>
                    <Col span={6}>Wholesale</Col>
                    <Col span={6}>Dates</Col>
                    <Col span={1}>Type</Col>
                    <Col span={2}>Delete</Col>
                </Row>
                {budgetsRender.map((item, index) => {
                    let subscriptionConnectedCampaign = false;
                    if (!!initialManagement.connectors && !!initialManagement.connectors.length) {
                        const subscriptionConnected = initialManagement.connectors.filter(
                            connector => String(connector.subscription_id) === item.subscription_id
                        );
                        subscriptionConnectedCampaign = subscriptionConnected.length ? subscriptionConnected[0] : false;
                    }
                    let type = 'M';
                    switch (item.type) {
                        case 'manual':
                            type = 'M';
                            break;
                        case 'odoo':
                            type = 'O';
                            break;
                        case 'spreadsheet':
                            type = 'S';
                            break;
                        case 'carryover':
                            type = 'C';
                            break;
                    }

                    return (
                        <Row
                            type="flex"
                            justify="space-around"
                            align="middle"
                            className="budget-row"
                            style={{ textAlign: 'center' }}
                        >
                            <Col span={9} strong>
                                <Tooltip title={item.subscription_id}>
                                    {item.subscription_name ? <Row>{item.subscription_name}</Row> : null}
                                </Tooltip>
                                <Row style={{ fontSize: '10px' }}>
                                    {subscriptionConnectedCampaign &&
                                    subscriptionConnectedCampaign.campaign_id in initialManagement.campaigns
                                        ? initialManagement.campaigns[subscriptionConnectedCampaign.campaign_id].name
                                        : null}
                                    {item.campaign_id && initialManagement.campaigns[item.campaign_id]
                                        ? initialManagement.campaigns[item.campaign_id].name
                                        : null}
                                </Row>
                            </Col>
                            <Col span={6} strong>
                                ${item.wholesale_price.toFixed(2)}
                            </Col>

                            <Col span={6}>
                                <Text>{moment(item.start_date).format(DATE_FORMAT_SHORT)}</Text>
                                <br />
                                <Text>{moment(item.end_date).format(DATE_FORMAT_SHORT)}</Text>
                            </Col>
                            <Col span={1}>
                                <Tooltip title={item.type}>
                                    <Text>{type}</Text>
                                </Tooltip>
                            </Col>
                            <Col span={2}>
                                {item.type !== 'odoo' ? (
                                    <div>
                                        <DeleteOutlined
                                            onClick={evt => {
                                                handleBudgetsDelete(index);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Col>
                        </Row>
                    );
                })}
            </Card>
        );
    };

    const handleNoticeChange = notice => {
        initialManagement.notice = notice.target.value;
        setInitialManagement(initialManagement);
    };

    const handleNoticeEditMode = () => {
        setNoticeEditMode(!noticeEditMode);
    };

    const renderNotificationModule = () => {
        return (
            <Card title="Notice">
                {noticeEditMode ? (
                    <React.Fragment>
                        <Input.TextArea
                            className="notice-edit-field-textarea"
                            defaultValue={initialManagement.notice}
                            onChange={handleNoticeChange}
                        ></Input.TextArea>
                        <Button onClick={handleNoticeEditMode}>Cancel</Button>
                        <Button
                            onClick={() => {
                                handleNoticeEditMode();
                                // updatingAdsManagement();
                            }}
                        >
                            Save
                        </Button>
                    </React.Fragment>
                ) : (
                    <Typography className="notice-edit-field" onClick={handleNoticeEditMode}>
                        {initialManagement.notice || '---Write notices here---'}
                    </Typography>
                )}
            </Card>
        );
    };

    const budgetsSpendPlanCalc = (datesArray, budgets) => {
        if (!budgets || !datesArray) {
            return;
        }
        let budgetsSpreaded = {};
        const budgetsAverage = [];
        budgets.forEach(budget => {
            budgetsAverage.push(
                budget.budget /
                    (moment(budget.endDate, DATE_TIME_FORMAT).diff(moment(budget.startDate, DATE_TIME_FORMAT), 'days') +
                        1)
            );
        });
        let total = 0;
        datesArray.forEach(dateD => {
            budgets.forEach((budget, index) => {
                total +=
                    moment(dateD).diff(moment(budget.startDate), 'days') >= 0 &&
                    moment(dateD).diff(moment(budget.endDate), 'days') <= 0
                        ? budgetsAverage[index]
                        : 0;
            });
            budgetsSpreaded[dateD] = total;
        });
        return budgetsSpreaded;
    };

    return editMode ? (
        <React.Fragment>
            <GADSBudgetAdd
                initialManagement={initialManagement}
                budgetAddMode={budgetAddMode}
                handleNewBudgetMode={handleNewBudgetMode}
                handleBudgetsChange={handleBudgetsChange}
                addLoggingRecord={addLoggingRecord}
            />
            <Modal
                width={'95%'}
                // title={'Account management: ' + editAccountName + '(' + props.editAccount + ')'}
                open={props.editMode && !!initialManagement}
                visible={props.editMode && !!initialManagement}
                footer={[
                    <Tooltip
                        key={'eneabling-' + initialManagement.gads_account}
                        title={'Enable/Disable GADS management'}
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={initialManagement.status === 'managed'}
                            onClick={() => {
                                props.handleChangeAccountStatus(initialManagement);
                            }}
                        />
                    </Tooltip>,
                    <Button key="Done" type="primary" onClick={handleCancel}>
                        Done
                    </Button>
                ]}
                onCancel={handleCancel}
            >
                <Spin size="large" spinning={loadingData}>
                    <Row>
                        <Col span={8}>
                            <Title
                                level={4}
                                style={{
                                    padding: '5px',
                                    margin: '2px',
                                    background: '#eeeeee',
                                    border: '2px solid skyblue'
                                }}
                            >
                                {initialManagement.name} (
                                <Tooltip title="Spended this month">
                                    $
                                    {initialManagement && initialManagement.bar_data
                                        ? (initialManagement.bar_data.costYesterdayAll / GADS_MICRO_MULTIPLIER).toFixed(
                                              2
                                          )
                                        : 0}
                                </Tooltip>
                                )
                            </Title>
                            {renderBudgets(initialManagement.budgets)}
                            {renderLoggingModule(initialManagement.notification)}
                            {/* {renderNotificationModule()} */}
                        </Col>
                        <Col span={16}>{renderCampaingsWeight(initialManagement.campaigns)}</Col>
                    </Row>

                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Table" key="1">
                            {renderTableFormat()}
                        </TabPane>
                        <TabPane tab="Graph" key="2">
                            {renderGraphFormat()}
                        </TabPane>
                        <TabPane tab="Graph Summarizing" key="3">
                            {renderGraphSumFormat()}
                        </TabPane>
                    </Tabs>
                </Spin>
            </Modal>
        </React.Fragment>
    ) : (
        ''
    );
};
